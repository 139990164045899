import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';


export default function Contact() {
    const [currentImg, setCurrentImg] = useState('bg-home-1');
    const [currentText, setCurrentText] = useState('think');

    useEffect(() => {

        setTimeout(() => {
            setCurrentImg('bg-home-2');
            setCurrentText('create');
            setTimeout(() => {
                setCurrentImg('bg-home-3');
                setCurrentText('belive');
                setTimeout(() => {
                    setCurrentImg('bg-home-4');
                    setCurrentText('you');
                }, 2000);
            }, 2000);
        }, 2000);
    }, []);


    return (
        <section className="fxt-content-wrap-layout4 " >
            <Container >
                <div className={currentImg} >
                    <Grid container spacing={15} justify="center">
                        <Grid item className="text-center" alignItems="center">
                            <div><h4 className="bg-home-text" >Four <span className="bg-home-text-1">{currentText}</span></h4></div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </section>

    );
}
import React from "react";
export default function CarouselMkt(props) {
    return (
        // <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        //     <ol className="carousel-indicators">
        //         <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
        //         <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        //         <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        //         <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>

        //     </ol>
        //     <div className="carousel-inner">
        //         <div className={`carousel-item ${props.tecno && 'active'}`}>
        //             <img className="d-block w-100" src="/img/services/mkt/banners/banner_1.jpg" alt="First slide" />
        //         </div>
        //         <div className={`carousel-item ${props.design && 'active'}`}>
        //             <img className="d-block w-100" src="/img/services/mkt/banners/banner_2.jpg" alt="Second slide" />
        //         </div>
        //         <div className={`carousel-item ${props.mkt && 'active'}`}>
        //             <img className="d-block w-100" src="/img/services/mkt/banners/banner_3.jpg" alt="Third slide" />
        //         </div>
        //         <div className={`carousel-item ${props.digital && 'active'}`}>
        //             <img className="d-block w-100" src="/img/services/mkt/banners/banner_4.jpg" alt="Third slide" />
        //         </div>
        //     </div>
        //     <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        //         <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        //         <span className="sr-only">Previous</span>
        //     </a>
        //     <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        //         <span className="carousel-control-next-icon" aria-hidden="true"></span>
        //         <span className="sr-only">Next</span>
        //     </a>
        // </div>
        <div id="carouselExampleIndicators" >
            <div className="carousel-inner">
                <div className={`carousel-item ${props.tecno && 'active'}`}>
                    <img className="d-block w-100" src="/img/services/mkt/banners/banner_1.jpg" alt="First slide" />
                </div>
                <div className={`carousel-item ${props.design && 'active'}`}>
                    <img className="d-block w-100" src="/img/services/mkt/banners/banner_2.jpg" alt="Second slide" />
                </div>
                <div className={`carousel-item ${props.mkt && 'active'}`}>
                    <img className="d-block w-100" src="/img/services/mkt/banners/banner_3.jpg" alt="Third slide" />
                </div>
                <div className={`carousel-item ${props.digital && 'active'}`}>
                    <img className="d-block w-100" src="/img/services/mkt/banners/banner_4.jpg" alt="Third slide" />
                </div>
            </div>
        </div>
    )
}

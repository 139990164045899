import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Header from '../common/Header';
import Socials from '../common/Socials';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
}));
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


export default function FullWidthGrid() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,
        checkedD: false,
        checkedE: false,
        checkedF: false,
        checkedG: false,
        checkedH: false,
        checkedI: false,
        checkedJ: false,
        checkedK: false,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    
    return (
        <div >
            <Header />
            <section className="fxt-content-wrap-layout4 " >
                <Container fixed>
                    <Grid container>
                        <Grid item xs={12} sm={6} className="containerRight">
                            <h4 className="text-right detail-contact little-title-2 ">Branding</h4>
                            <FormControlLabel
                                control={<GreenCheckbox checked={state.checkedA} onChange={handleChange} name="checkedA" />} label="Branding" />
                            <FormControlLabel
                                control={<GreenCheckbox checked={state.checkedB} onChange={handleChange} name="checkedB" />} label="Rebranding" />
                            <h4>Aplicaciones de marca</h4>
                            <Grid >
                                <div className="textLeftgr2" >
                                    <h3 className="textLeftgr">Diseño digital</h3>
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={state.checkedE} onChange={handleChange} name="checkedE" />} label="Foto de perfil(FB e IG)" />
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={state.checkedF} onChange={handleChange} name="checkedF" />} label="Plantilla de post en redes" />
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={state.checkedG} onChange={handleChange} name="checkedG" />} label="Banner para FB" />
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={state.checkedH} onChange={handleChange} name="checkedH" />} label="Revista|Libro" />
                                </div>
                                <div className="textRightgr2">
                                    <h3 className="textRightgr">Diseño impreso</h3>
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={state.checkedI} onChange={handleChange} name="checkedI" />} label="Tarjetas de presentación" />
                                    <FormControlLabel
                                        control={<GreenCheckbox checked={state.checkedJ} onChange={handleChange} name="checkedJ" />} label="Brauche|Catalogo de serviciós" />
                                </div>
                                <TextField className="textBran" label="¿Qué presupuesto tienes para tu campaña?" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} className="containerLeft">
                            <h4 className="text-right detail-contact little-title-3"> Marketing</h4>
                            <FormControlLabel
                                control={<GreenCheckbox checked={state.checkedC} onChange={handleChange} name="checkedC" />} label="Manejo de Redes sociales" />
                            <Socials style={{ Color: "#E2E2E2" }} />
                            <FormControlLabel
                                control={<GreenCheckbox checked={state.checkedD} onChange={handleChange} name="checkedD" />} label="Campaña de Redes sociales" />
                            <Socials style={{ color: "#E2E2E2" }} />
                            <Grid>
                                <TextField className="textMark" label="¿Qué presupuesto tienes para tu campaña?" />
                            </Grid>
                            <Grid>
                                <FormControlLabel
                                    control={<GreenCheckbox checked={state.checkedK} onChange={handleChange} name="checkedK" />} label="Campaña de Email Marketing" />
                            </Grid>
                        </Grid>
                        <Button  className="button3 title-general text-white" >
                                    Siguiente
                                </Button>
                    </Grid>
                </Container>
            </section>
        </div>
    );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaperServices from './PaperServices';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 440,
    width: 250,
    borderRadius: 25
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function CardService() {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  return (
    <Grid container className={classes.root} spacing={10}>
      <Grid item xs={15} >
        <Grid container justify="center" spacing={spacing}>
          <Grid item xs>
            <PaperServices
              title="Branding"
              subtitle="Explotamos al máximo nuestra creadividad para crearte la identida visual que necesitas"
              image="/img/anim/branding.png"
              link ="/services/mkt"
            />
          </Grid>
          <Grid item xs>
            <PaperServices
              title="Marketing Digital"
              subtitle="Creamos presencia, ventas y fanatismo a través de nuestras herramientas"
              image="/img/anim/marketing.png"
              link ="/services/design"
            />
          </Grid>
          <Grid item xs>
            <PaperServices
              title="Sitios web"
              subtitle="Más que una página bonita, un sitio web de impacto"
              image="/img/anim/websites.png"
              link ="/services/digital"
            />
          </Grid>
          <Grid item xs>
            <PaperServices
              title="App Móvil"
              subtitle="Genera nuevas experiencias de compra o entretenimiento para tus clientes"
              image="/img/anim/apps.png"
              link ="/services/tecnology"
            />
          </Grid>
          <Grid item xs>
            <PaperServices
              title="Producción Audiovisual"
              subtitle="Genera experiencias virtuales para tus clientes"
              image="/img/anim/audiovisual.png"
              link ="/services/tecnology"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
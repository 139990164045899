import React, { useEffect, useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import InputText from '../components/InputText';
import Index from '../screens/home/Index';

export default function Contact() {
    const [intro, setIntro] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIntro(false)
        }, 8000);
    }, []);
    return (
        <>
            {
                intro ?
                    <Index />
                    :
                    <div className="BackImage">
                        <Header />
                        <section className="fxt-content-wrap-layout4 " >
                                <Grid container  justify="center">
                                    <Grid item className="text-center" alignItems="center">
                                        <h4 className="text-left detail-contact TextHello up-title" >Hello</h4>
                                        <h4 className="text-left detail-contact TextHello down-title" >Hello</h4>
                                        <Card className="PaddingContact">
                                            <div>
                                                <h4 className="text-left detail-contact TextHello middle-title" > Hello</h4>
                                            </div>

                                            <h4 className="text-right detail-contact little-title " >
                                                Queremos <br /> conocerte
                                            </h4>
                                            <InputText fullWidth label="Nombre y apellidos" />
                                            <InputText fullWidth label="Correo electrónico" />
                                            <InputText fullWidth label="Telefono" />
                                            <InputText fullWidth label="Ciudad/Pais" />
                                            <h2 className="text-center detail-contact " >
                                                Preséntanos tu marca o proyecto
                                            </h2>
                                            <InputText fullWidth label="Empresa" />
                                            <InputText fullWidth label="Sitio web" />
                                            <InputText fullWidth label="Link a redes sociales" />
                                            <Button className="title-general button1 wow animate__animated animate__bounceInUp " >
                                                Siguiente
                                            </Button>
                                        </Card>

                                    </Grid>
                                </Grid>
                        </section>
                        <Footer white />
                    </div>
            }
        </>
    );
}
import React from 'react'
import Socials from './Socials'
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

export default function Footer(props) {
    return (
        <div className="footer" style= {props.bgBlack  && {backgroundColor:'black'}}>
            <Grid container spacing={12}>
                <Grid item xs={6} className="text-center" >
                    <span to="/pp" style={{color: props.black? 'black' :'black'}}>Copyright© 2020 - Todos los Derechos reservados a 4Think </span>
                </Grid>
                
                <Grid item xs={6} className="text-center">
                    <Link to="/terms" style={{color: props.black? 'Black' :'black'}}>Términos y Condiciones | </Link>
                    <Link to="/pp" style={{color: props.black? 'Black' :'black'}}>Politícas de privacidad</Link>
                </Grid>
            </Grid>
           
        </div>
    )
}

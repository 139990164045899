import React, {useState} from 'react'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 440,
    width: 260,
    borderRadius: 25,
    
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function PaperServices(props) {
  const classes = useStyles();
  const { title, subtitle, buttonTitle, image, link, anim } = props;

  const [state, setState] = React.useState({
    checkedG: false,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Paper  className={[classes.paper, "bord wow animate__animated animate__backInDown spacejetAnim"]}>
      <img className="spacejet" src={image} ></img>
      <h3 className="title-paper wow animate__animated animate__bounceInLeft " style={{ height: 60 }}>{title}</h3>
      <p style={{ height: 80, padding:20 }}>{subtitle}</p>
      <FormControlLabel
        control={<GreenCheckbox checked={state.checkedG} onChange={handleChange} name="checkedG" />}
      />
    </Paper>
  )
}

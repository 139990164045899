import React, { useEffect } from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import Home from './screens/home/Index';
import Services from './screens/services/Index';
import Marketing from './screens/services/Marketing';
import Design from './screens/services/Design';
import Digital from './screens/services/Digital';
import Tecnology from './screens/services/Tecnology'
import Projects from './screens/Projects';
import About from './screens/About';
import Contact from './screens/Contact';
import Terms from './screens/Terms';
import { isMobile } from "react-device-detect";

function App() {
  useEffect(() => {
    checkMobile()
  }, [])

  const checkMobile = () => {
    if (isMobile) {
      window.location = "https://m.4thinkdigital.com";
    }
  }
  return (
    <Switch>
      <Route exact path="/" component={Contact} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/services/mkt" component={Marketing} />
      <Route exact path="/services/design" component={Design} />
      <Route exact path="/services/digital" component={Digital} />
      <Route exact path="/services/tecnology" component={Tecnology} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/terms" component={Terms} />
    </Switch>
  );
}

export default App;

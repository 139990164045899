import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Header from '../common/Header';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default function FullWidthGrid() {

    return (
        <div >
            <Header black />
            <section className="fxt-content-wrap-layout4 " >
                <Container >
                    <Grid className="contentAll2">
                        <Grid className="contentAll">

                            <Grid className="textCot">
                                <h4>Esmeralda Guillen Castillo</h4>
                                <h6>Fecha 11/04/2021</h6>
                                <h6>Cotización #25468</h6>
                            </Grid>
                            <img className="logoLeft" src="/img/logo.jpg" />

                            <Grid className="content1"
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="baseline">
                                <h5>Servicio</h5>
                                <h5>Periodicidad</h5>
                                <h5 >Inversión(MXN)</h5>
                            </Grid>
                            <Grid className="content2"
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="baseline">
                                <h5>Branding</h5>
                                <h5 className="invText2">Pago único</h5>
                                <h5 className="invText">$4,500.00</h5>
                            </Grid>
                            <div className="textCont">
                                <h6 className="appText">Aplicaciones de marca</h6>
                                <h6 className="appText2"> Tarjetas de presentación </h6>
                                <h6 className="appText2"> Brouchure|Folleto de servicios </h6>
                            </div>
                            <Grid className="content3"
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="baseline">
                                <h5>Marketing Digital</h5>
                                <h5 className="invText3">Mensual</h5>
                                <h5 className="invTex4">$8,500.00</h5>
                            </Grid>
                            <h6>Manejo de redes sociales</h6>
                            <h7 className="appText3"> Facebook,Instagram</h7>
                            <Grid className="content3 content4"
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="baseline">
                                <h5 className="textTot">Total</h5>
                                <h5 className="textTot2" >$14,000.00*</h5>
                            </Grid>
                            <Grid >
                                <h7 className="textbottom">*Este es un costo aproximado. NO incluye IVA</h7>
                            </Grid>
                        </Grid>
                    </Grid>


                </Container>

            </section>
            <Button className="buttonCont">
                Contactar
            </Button>
            <Button className="buttonCont2">
                <img src={`/img/pdf.png`}/>
            </Button >
            <Button className="buttonCont3" >
                <img src={`/img/volver.png`}/>
            </Button>
        </div>
    );
}